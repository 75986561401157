<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button v-if="isUserAdmin" @click="editDelivery" variant="secondary" class="mr-1"><feather-icon icon="Edit2Icon"/> {{ $t('Editar envío') }}</b-button>
      <b-button v-if="showEditWarehouse" @click="openWarehouse('ModalWarehouseCompanies')" variant="secondary"><feather-icon icon="Edit2Icon"/> {{ $t('Editar bodega') }}</b-button>
    </div>
    <div class="mt-2">
      <h4>{{$t('Datos de origen')}}</h4>
      <form-render class="mb-2" :fields="fields" :key="keyFormRender" :form.sync="form" ref="formRenderDelivery" containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2">
        <template #company>
          <div class="d-flex align-items-end">
            <div class="w-100">
              <label class="label-form"
                ><span>{{$t("Empresa")}}</span> <span class="text-danger">*</span></label
              >
              <field-input
                  :value.sync="form.company"
                  name="company"
                  placeholder="Ingrese empresa"
                  :disabled="true"
              />
            </div>
            <div class="ml-1" v-if="isUserAdmin">
              <b-button @click="goCompany" target="_blank" variant="outline-light" v-b-tooltip :title="$t('Ir a empresa')" size="md"><feather-icon icon="ExternalLinkIcon"/></b-button>
            </div>
          </div>
        </template>
        <template #warehouse>
          <div class="d-flex align-items-end">
            <div class="w-100">
              <label class="label-form"
                ><span>{{$t('Lugar de retiro / Bodega')}}</span> <span class="text-danger">*</span></label
              >
              <field-select
              v-bind="form.warehouse"
              :value.sync="form.warehouse"
              :options="warehouses"
              :disabled="isDisabledEdit"
              />
            </div>
            <div class="ml-1" v-if="!isDisabledEdit">
              <b-button :disabled="isDisabledEdit" @click="openWarehouse('ModalWarehouseCompanies', true)" variant="outline-light" v-b-tooltip :title="$t('Crear nueva bodega o lugar de retiro')" size="md"><feather-icon icon="PlusIcon"/></b-button>
            </div>
          </div>
        </template>
      </form-render>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6 border-top">
        <h4 class="mt-2">{{$t('Datos destinatario')}}</h4>
        <form-render class="mb-2" :fields="fields2" :key="keyFormRender2" :form.sync="form2" ref="formRenderDestination" containerButtonsClass="col-sm-12 col-md-3 container-button">
          <template #infoPudo>
            <label v-if="infoPudo">Dirección:</label>
            <div>
              <b>{{infoPudo}}</b>
            </div>
          </template>
          <template #infoAgency>
            <label v-if="infoAgency">Dirección:</label>
            <div>
              {{infoAgency}}
            </div>
          </template>
        </form-render>
      </div>
      <div class="col-sm-12 col-md-6 border-top">
        <h4 class="mt-2">{{$t('Datos de distribución')}}</h4>
        <form-render class="mb-2" :fields="fields3" :key="keyFormRender3" :form.sync="form3" ref="formRenderDistribution" containerButtonsClass="col-sm-12 col-md-3 container-button"></form-render>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6" :class="{ 'border-top': showExtraFields }">
        <div v-if="showExtraFields">
          <h4 class="mt-2">{{$t('Campos adicionales')}}</h4>
          <form-render class="mb-2" :fields="fields4" :key="keyFormRender4" :form.sync="form4" ref="formRenderDestination" containerButtonsClass="col-sm-12 col-md-3 container-button"></form-render>
        </div>
      </div>
      <div v-if="showCashOnDelivery" class="col-sm-12 col-md-6 border-top">
        <h4 class="mt-2">{{$t('COD - Cash on delivery (Pago contra entrega)')}}</h4>
        <timeline :timeline.sync="timeline" type="lineal" :styleColor="timelineCODstyleColor ? timelineCODstyleColor : ''" :showIconAllActive="true" ref="timelineCOD"></timeline>
        <table-render
        class="my-1 px-1"
        :rows="rows"
        :schema="schema"
        :show-checkboxes="false"
        :stickyHeader="`calc(100vh - 2.5rem)`"
        id="status_history">
        </table-render>
      </div>
    </div>
    <div class="float-right" v-if="showButtonEdit">
      <div v-if="showEstimatedPrice">
        <p v-if="!!delivery.estimated_price" >Precio estimado del envío: <strong>{{delivery.estimated_price}}</strong><strong v-if="showIgv"> + IGV</strong></p>
      </div>
      <b-button variant="warning" @click.prevent="onEditDelivery()" :disabled="isDisabledEdit">
        <b-spinner v-show="loading.update" small></b-spinner>
        {{$t('Actualizar información')}}
      </b-button>
    </div>
    <modal-warehouse-companies :formTab="formTab" :companyId="companyId"></modal-warehouse-companies>
    <modal-maps :dataDelivery="dataDelivery" @putDelivery="fetchEditDelivery"/>
  </div>
</template>

<script>
import ModalWarehouseCompanies from '@/views/modules/companies/companies-id/warehouses/ModalWarehouseCompanies.vue'
import internalLevels from '@/views/modules/deliveries/list-deliveries/detail-delivery/internalLevels'
import { mapGetters } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services'
import ModalMaps from '../../../create/new-delivery/ModalMaps.vue'

export default {
  components: { ModalWarehouseCompanies, FeatherIcon, ModalMaps },
  name: 'delivery-info',
  props: ['warehouses'],
  data () {
    return {
      infoPudo: '',
      infoAgency: '',
      fields: [],
      fields2: [],
      fields3: [],
      fields4: [],
      keyFormRender: 1,
      keyFormRender2: 1,
      keyFormRender3: 1,
      keyFormRender4: 1,
      optionsDistribution: [
        { id: 'home', text: 'Domicilio', value: true },
        { id: 'pudo', text: 'Punto de retiro', value: false },
        { id: 'agency', text: 'Agencia de courier', value: false }
      ],
      form: {},
      form2: {},
      form3: {},
      form4: {},
      formTab: {},
      isDisabledEdit: true,
      loading: {
        update: false,
        levels: true
      },
      timelineCODstyleColor: null,
      timeline: [],
      groups: {
        TO_COLLECT : {
          status: { code: 'TO_COLLECT', active: false, title: 'Por recaudar' }, scope: [1]
        },
        COLLECT_TO_CONFIRM : {
          status: { code: 'COLLECT_TO_CONFIRM', active: false, title: 'Recaudo por confirmar' }, scope: [5]
        },
        COLLECTED_BY_ENVIAME : {
          status: { code: 'COLLECTED_BY_ENVIAME', active: false, title: 'Recaudado por enviame' }, scope: [2, 3]
        },
        COLLECTED_PAYED : {
          status: { code: 'COLLECTED_PAYED', active: false, title: 'Pagado' }, scope: [4]
        },
        NOT_COLLECT_APPLY : {
          status: { code: 'NOT_COLLECT_APPLY', active: false, title: 'No aplica recaudo' }, scope: [6]
        }
      },
      schema: [],
      rows: [],
      pudos: [],
      showModalWarehouse: false,
      dataDelivery: null,
      baseService: new BaseServices(this)
    }
  },
  mounted() {
    this.setInitialData()
    this.setInitialForm()
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      generalLoading: 'getLoading',
      currentDelivery: 'getDeliveryDetails',
      levels: 'getLevels',
      places: 'getPlacesByCountry',
      zipCode: 'getZipcode',
      getPudos: 'getPudosPlat',
      getCarriers: 'getCourierConfiguration',
      courierCompanies: 'getCouriers',
      countries: 'getCountries'
    }),
    urlPlatform() {
      return environment.platformUrl
    },
    delivery() {
      return this.currentDelivery.delivery
    },
    isUserAdmin() {
      return ['admin', 'superadmin']?.includes(this.mySession?.role)
    },
    isUserFreemium() {
      return ['ecommerce'].includes(this.mySession?.role) && this.mySession?.user_access?.company_category_id === 2
    },
    showEditWarehouse() {
      return !this.isUserFreemium
    },
    companyId() {
      return this.delivery?.company_id
    },
    showAmountToCollect() {
      return ((this.delivery?.company?.country_id === 3) || (['SVPE', 'MX99', 'URP', 'UBN', 'BLX', 'MXYG', 'URPB', 'PEMV'].includes(this.delivery?.carrier?.code))) && this.form3?.attribute?.findIndex(code => code.code.startsWith('cod')) !== -1 && !!this.delivery?.amount_to_collect
    },
    attributeHasData() {
      return this.form3?.attribute?.findIndex(code => code.code.startsWith('cod')) !== -1
    },
    showCashOnDelivery() {
      return !!this.currentDelivery?.collectionData
    },
    showTypeAndAtrribute() {
      return this.mySession?.special_permissions?.includes('create-deliveries-with-type-and-attributes')
    },
    showExtraFields() {
      return this.extraFieldsOptional.length > 0
    },
    extraFieldsOptional() {
      return this.currentDelivery?.extra_fields?.filter((field) => field?.in['delivery-details']) ?? []
    },
    showButtonEdit() {
      return ['admin', 'superadmin', 'ecommerce', 'seller', 'marketplace'].includes(this.mySession?.role)
    },
    showIgv() {
      return this.delivery?.company?.company_id === 4
    },
    showEstimatedPrice() {
      return !this.isUserAdmin && this.currentDelivery?.showEstimatedPrice
    },
    country() {
      return {
        id: this.delivery?.country?.id,
        code: this.delivery?.country?.code?.toUpperCase(),
        required: internalLevels[this.delivery?.country?.code?.toLowerCase()]?.required ?? [],
        display: internalLevels[this.delivery?.country?.code?.toLowerCase()]?.display ?? []
      }
    },
    selectorsLevel() {
      let selectors = {}
      if (this.levels) {
        selectors = this.$generatePlacesLevels(this.$generateArrayPlacesFromTree(this.levels), Math.max(this.country.required), this.country.display)
      }
      return selectors
    },
    keysSelectorLevel() {
      return !!this.selectorsLevel ? Object.keys(this.selectorsLevel) : []
    },
    texts() {
      return this.$i18nImport(`${this.country?.code?.toLowerCase()}.es`)[`${this.country?.code?.toLowerCase()}.es`]
    },
    carriers() {
      return this.courierCompanies?.map((carrier) => ({...carrier, id: carrier.code})) ?? []
    },
    currentCarrier() {
      return this.courierCompanies?.find((carrier) => carrier.code === (this.form3?.carrier?.code ?? this.delivery?.carrier?.code)) ?? {}
    },
    services() {
      return this.currentCarrier?.services ?? []
    },
    currentService() {
      return this.delivery?.extra_fields['service']?.value ?? this.delivery?.carrier_service?.code
    },
    types() {
      return this.currentCarrier?.types?.map((el) => ({...el, id: el.code })) ?? []
    },
    attributes() {
      let attr = []
      if (['MUCO', 'COO', 'CO99', 'TCC', 'ITR', 'QUIC'].includes(this.delivery?.carrier?.code)) {
        attr = this.currentCarrier?.attributes?.filter(el => el.code.indexOf('cod') !== -1)
        attr = attr?.filter(el => el.code.indexOf('codEF') === -1)
      } else {
        attr = this.currentCarrier?.attributes
      }
      if (!['admin'].includes(this.mySession.role)) {
        if (this.currentCarrier.code?.toLowerCase() === 'fdx') attr = attr.filter(att => att.code !== 'volume')
      }
      return attr?.map((el) => ({...el, id: el.code, text: el.name })) ?? []
    },
    showMarketplaceInputs() {
      return !!this.currentDelivery?.delivery?.marketplace && ['admin', 'superadmin']?.includes(this.mySession?.role)
    }
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.update = !!this.generalLoading.putUpdateDelivery
        this.loading.levels = !!this.generalLoading.getLevels
      },
      deep: true
    },
    delivery() {
      this.setInitialData()
      this.setInitialForm()
    },
    showAmountToCollect(value) {
      const index = this.fields3.findIndex((field) => field.name === 'amount_to_collect')
      if (!value) {
        this.fields3.splice(index, 1)
      } else if (index === -1) {
        const findIndex = this.fields3?.findIndex((field) => field.name === 'length') + 1
        this.fields3.splice(findIndex, 0, { fieldType: 'FieldInput', type: 'number', name: 'amount_to_collect', label: this.$t('Monto a recolectar'), containerClass: this.showTypeAndAtrribute ? 'col-sm-12 col-md-3 container-info' : 'col-sm-12 col-md-4 container-info', iconLeft: {icon: 'DollarSignIcon', stroke: 1.5, color: '#BBBBBB'} })
      }
    },
    courierCompanies() {
      this.form3.service = this.currentCarrier?.services?.find((service) => service.code === this.currentService) ?? null
    },
    currentCarrier() {
      this.setOptionsCarrieLevel()
    },
    levels() {
      this.setFormLevel()
      // this.setOptionsLevels()
      this.keyFormRender2++
    },
    zipCode(value) {
      this.setSelectOptions('zip_code', this.fields2, value?.map(({id, name}) => ({id, text: name})) ?? [])
    },
    getPudos(value) {
      const { pudos } = value
      if (pudos.length > 0) {
        this.pudos = pudos.map((pudo) => ({...pudo, text: `${pudo?.name} (${pudo?.code})`}))
        this.setSelectOptions('pudo', this.fields2, this.pudos ?? [])
        this.keyFormRender2++
      }
    },
    showMarketplaceInputs(value) {
      if (value) {
        this.fields.push(
          { fieldType: 'FieldInput', name: 'marketplace_id', label: this.$t('ID Marketplace'), containerClass: 'col-sm-12 col-md-1 container-info', disabled: true },
          { fieldType: 'FieldInput', name: 'marketplace', label: this.$t('Marketplace'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true },
          { fieldType: 'FieldInput', name: 'marketplace_code', label: this.$t('Código Marketplace'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true })
      }
    }
  },
  methods: {
    async onEditDelivery() {
      const delivery = await this.$refs.formRenderDelivery.onlyCheckForm()
      const destination = await this.$refs.formRenderDestination.onlyCheckForm()
      const distribution = await this.$refs.formRenderDistribution.onlyCheckForm()
      if (delivery && destination && distribution) {
        const payload = {
          imported_id: this.form3?.imported_id,
          product: this.form3?.description,
          n_packages: this.form3?.n_packages,
          information: this.form2?.description,
          price: this.form3?.price,
          weight: this.form3?.weight,
          volume: this.form3?.volume,
          name: this.form2?.name,
          phone: this.form2?.cellphone,
          email: this.form2?.email,
          company_id: this.form?.company_id,
          country_id: this.delivery?.country_id,
          warehouse_id: this.form?.warehouse?.id,
          warehouse_address_id: this.form?.place?.id,
          warehouse: { address: {full_address: this.form.warehouse_address, place_id: this.form?.place?.id}},
          carrier_code: this.form3?.carrier?.id,
          carrier: this.form3?.carrier,
          service_code: this.form3?.service?.code,
          service: this.form3.service,
          tracking_number: this.form3?.tracking_number,
          dimensions: { width: this.form3?.width, height: this.form3?.height, length: this.form3?.length },
          attribute: this.form3?.attribute?.map((form) => form.code) ?? null,
          carrier_attribute: this.form3?.attribute?.map((form) => form.code) ?? null,
          type: this.form3?.type?.code ?? null,
          carrier_type: this.form3?.type?.code ?? null,
          amount_to_collect: this.form3?.amount_to_collect ?? null
        }
        if (!!this.form2?.pudo) {
          payload.pudo_id = this.form2?.pudo?.legacy_data?.platform_pudo_id
          payload.pudo = this.form2?.pudo
          payload.full_address = this.infoPudo
          delete payload.place_id
          delete payload.address
          delete payload.zip_code
        } else {
          this.country.display.forEach((level, index) => {
            if (index !== this.country.display.length - 1) payload[`place_level_${level}`] = this.form2[`level${level}`]
            else if (!!this.form2[`level${level}`]) payload.place_id = this.form2[`level${level}`].platform_id
          })
          payload.full_address = this.form2?.full_address ?? null
          payload.zip_code = this.form2?.zip_code?.id ?? this.form2?.zip_code ?? null
          payload.place = this.delivery.address
          if (this.country.code === 'MX') {
            payload.place_id = this.form2.level4.id
          }
        }

        const carrierCodes = ['UBN', 'PYPE', 'PUP', 'COMV', 'UBN', 'PEYG']
      
        if (carrierCodes.includes(payload.carrier_code)) {
          // this.$refs.createDeliveryForm.updateButtonForm(false)
          this.confirmAddressFromMap(payload)
        } else {
          // Si no cumple con la condición llamamos a la función que llama directamente al servicio de agregar envio
          this.fetchEditDelivery(payload)
        }
      }
    },
    fetchEditDelivery(payload) {
      const data = {
        name: 'putUpdateDelivery',
        params: { delivery_id: this.$route.params.id },
        queryParams: { ...payload },
        onSuccess: () => {
          this.$bvModal.hide('ModalMaps')
          this.$emit('getDelivery')
          this.$success(this.$t('Envío actualizado con éxito'))
        },
        onError: () => this.loading.update = false
      }
      this.$store.dispatch('fetchService', data)
    },
    async setInitialData () {
      this.fields = [
        { fieldType: 'FieldInput', name: 'company_id', label: this.$t('ID empresa'), containerClass: 'col-sm-12 col-md-1 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'company', useSlot:true, containerClass: 'col-sm-12 col-md-3 container-info', disabled: true },
        { fieldType: 'FieldSelect', name: 'warehouse', useSlot:true, containerClass: 'col-sm-12 col-md-3 container-info', options: [], change: this.changeWarehouse },
        { fieldType: 'FieldInput', name: 'warehouse_address', label: this.$t('Dirección de retiro'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true, validation: 'required'},
        { fieldType: 'FieldSelect', name: 'place', containerClass: 'col-sm-12 col-md-2 container-info', options: this.places, change: this.changeWarehouse, validation: 'required', disabled: true }
      ]
      this.fields2 = [
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', disabled: true },
        { fieldType: 'FieldInput', name: 'cellphone', label: 'Celular', containerClass: 'col-sm-12 col-md-6 container-info', disabled: true, prepend : {text: `+${ this.delivery?.country_phone_code }`, color: '#BBBBBB'} },
        { fieldType: 'FieldInput', type: 'email', name: 'email', label: 'Correo', containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
        {
          fieldType: 'FieldRadio',
          name: 'distributionType',
          label: 'Tipo de distribución',
          containerClass: 'col-sm-12 col-md-12 container-info',
          validation: 'required',
          align: 'h', change: this.changeDistributionType,
          options: this.optionsDistribution,
          disabled: true
        },
        { fieldType: 'FieldTextarea', name: 'description', label: 'Observaciones', containerClass: 'col-sm-12 container-info', disabled: true }
      ]
      this.fields3 = [
        { fieldType: 'FieldInput', name: 'imported_id', label: this.$t('Nº de referencia'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'tracking_number', label: this.$t('Orden de transporte del courier'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
        { fieldType: 'FieldSelect', name: 'carrier', label: this.$t('Carrier'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', change: this.changeCarrier, disabled: true},
        { fieldType: 'FieldSelect', name: 'service', label: this.$t('Servicio'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', disabled: true },
        { fieldType: 'FieldInput', name: 'weight', type: 'number', label: this.$t('Peso (kg)'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'volume', type: 'number', label: this.$t('Volumen'), containerClass: 'col-sm-12 col-md-3 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'n_packages', label: this.$t('Nº de bultos'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required', disabled: true },
        { fieldType: 'FieldInput', name: 'price', type: 'number', label: this.$t('Valor producto'), containerClass: 'col-sm-12 col-md-3 container-info', validation: 'required', disabled: true, iconLeft: {icon: 'DollarSignIcon', stroke: 1.5, color: '#BBBBBB'} },
        { fieldType: 'FieldInput', name: 'height', type: 'number', label: this.$t('Alto (cm)'), containerClass: this.showTypeAndAtrribute ? 'col-sm-12 col-md-3 container-info' : 'col-sm-12 col-md-4 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'width', type: 'number', label: this.$t('Ancho (cm)'), containerClass: this.showTypeAndAtrribute ? 'col-sm-12 col-md-3 container-info' : 'col-sm-12 col-md-4 container-info', disabled: true },
        { fieldType: 'FieldInput', name: 'length', type: 'number', label: this.$t('Largo (cm)'), containerClass: this.showTypeAndAtrribute ? 'col-sm-12 col-md-3 container-info' : 'col-sm-12 col-md-4 container-info', disabled: true },
        { fieldType: 'FieldTextarea', name: 'description', label: this.$t('Descripción del producto'), containerClass: 'col-sm-12 container-info', disabled: true }
      ]
      if (this.extraFieldsOptional.length > 0) this.fields4 = this.extraFieldsOptional.map((field) => ({ fieldType: field.key === 'customer_satisfaction_survey' ? 'FieldTextarea' : 'FieldInput', name: field.key, label: this.$t(field.label), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true }))
      if (this.fields4.length > 0) this.form4 = Object.fromEntries(new Map([...this.fields4?.map((f) => ([f.name, f.name === 'entel_slot' ? this.delivery?.extra_fields[f.name]?.value?.message : this.delivery?.extra_fields[f.name]?.value ?? 'S/I']))]))
      if (this.currentDelivery?.collectionData) this.generateTimeline(this.currentDelivery?.collectionData)
      if (this.delivery) this.changeDistributionType('setInitialData', this.delivery?.pudo ? this.delivery?.pudo?.carrier_id ? this.optionsDistribution[2] : this.optionsDistribution[1] : this.optionsDistribution[0])
      this.fields2 = this.fields2?.map((field) => ({ ...field, disabled: true }))
      if (!!this.country.code) {
        const label = this.texts[`Place_level${this.country.required[0]}`] ?? ''
        this.fields.find((field) => field.name === 'place').label = `${label} de retiro`
      }
      if (this.showTypeAndAtrribute) {
        const findIndex = this.fields3?.findIndex((field) => field.name === 'service') + 1
        this.fields3.splice(findIndex, 0, ...[
          { fieldType: 'FieldSelect', name: 'type', label: this.$t('Tipo'), containerClass: 'col-sm-12 col-md-6 container-info', disabled: true },
          { fieldType: 'FieldSelect', name: 'attribute', label: this.$t('Atributo'), containerClass: 'col-sm-12 col-md-6 container-info', multiple: true, disabled: true }
        ])
        this.setSelectOptions('attribute', this.fields3, this.attributes.map(attr => ({...attr, disabled: true})) ?? [])
      }

      if (this.showAmountToCollect) {
        const findIndex = this.fields3?.findIndex((field) => field.name === 'length') + 1
        this.fields3.splice(findIndex, 0, { fieldType: 'FieldInput', type: 'number', name: 'amount_to_collect', label: this.$t('Monto a recolectar'), containerClass: this.showTypeAndAtrribute ? 'col-sm-12 col-md-3 container-info' : 'col-sm-12 col-md-4 container-info', disabled: true, iconLeft: {icon: 'DollarSignIcon', stroke: 1.5, color: '#BBBBBB'} })
      }
      // if (this.delivery?.company_id === 3063) {
      if (this.delivery?.can_intl_delivery) {
        this.optionsDistribution.push({ id: 'intl', text: 'Envío internacional', value: false })
      }
      this.isDisabledEdit = true
      this.keyFormRender++
      this.keyFormRender2++
      this.keyFormRender3++
      this.keyFormRender4++
    },
    setInitialForm() {
      this.form = {
        company: this.delivery?.company.name1 ?? '',
        company_id: this.delivery?.company_id ?? '',
        warehouse: {id: this.delivery?.warehouse?.id ?? 0, text: this.delivery?.warehouse?.name ?? ''},
        warehouse_address: this.delivery?.warehouse?.address?.full_address ?? '',
        place: {id: this.delivery?.warehouse?.address?.place?.id ?? 0, text: this.delivery?.warehouse?.address?.place.name ?? ''}
      }
      if (this.delivery?.marketplace) {
        this.form = {
          ...this.form,
          marketplace_id: this.delivery?.marketplace?.id,
          marketplace: this.delivery?.marketplace && this.delivery?.marketplace?.name2 ? `${this.delivery.marketplace.name1} - ${this.delivery.marketplace.name2}` : this.delivery?.marketplace ? `${this.delivery.marketplace.name1}` : '',
          marketplace_code: this.delivery?.marketplace?.code ?? ''
        }
      }
      this.form2 = {
        name: this.delivery?.name,
        cellphone: this.delivery?.phone,
        email: this.delivery?.email,
        distributionType: this.delivery?.pudo ? this.delivery?.pudo?.carrier_id ? this.optionsDistribution[2] : this.optionsDistribution[1] : this.optionsDistribution[0],
        description: this.delivery?.information,
        full_address: this.delivery?.address?.full_address,
        zip_code: this.delivery?.address?.zip_code  ?? JSON.parse(this.delivery?.place_name?.customization || '{}')?.zip_code ?? null
      }
      this.form3 = {
        imported_id: this.delivery?.imported_id,
        tracking_number: this.delivery?.tracking_number,
        carrier: { id: this.delivery?.carrier?.code, text: this.delivery?.carrier?.name },
        service: this.services?.find((service) => service.code === this.currentService) ?? null,
        type: this.types?.find((type) => type.id === this.delivery?.extra_fields['carrier_type']?.value) ?? null,
        attribute: this.attributes?.filter((attr) => this.delivery?.extra_fields['attributes']?.value?.some(item => attr.id === item)) ?? null,
        height: this.delivery?.dimensions?.height,
        width: this.delivery?.dimensions?.width,
        length: this.delivery?.dimensions?.length,
        weight: this.delivery?.weight,
        volume: this.delivery?.volume,
        price: this.delivery?.price,
        n_packages: this.delivery?.n_packages,
        description: this.delivery?.product,
        amount_to_collect: this.delivery?.amount_to_collect
      }
      
      this.setFormLevel()
      this.keyFormRender++
      this.keyFormRender2++
      this.keyFormRender3++
      this.keyFormRender4++
      if (this.form.company_id) {
        this.$store.dispatch('fetchService', { name: 'getCouriers', queryParams: {shipper_id: this.form.company_id} })
      }
    },
    openModal(name) {
      this.$bvModal.show(name)
    },
    openWarehouse(name, flat) {
      this.showModalWarehouse = true
      if (flat) {
        this.formTab = {}
      } else {
        this.formTab = {...this.delivery?.warehouse}
      }
      this.openModal(name)
    },
    setFormLevel() {
      // if (this.form2.distributionType.id !== 'pudo' && !!this.delivery?.country?.code) {
      if (this.delivery) {
        // internalLevels[this.delivery?.country?.code.toLowerCase()].display.forEach(level => {
        //   const customization = JSON.parse(this.delivery?.place_name?.customization) ?? {}
        //   if (this.delivery.country_id === 1) customization['Level3_name'] = this.delivery?.place_name?.name; customization['Level3_id'] = this.delivery?.place_name?.id
        //   if (this.delivery.country_id === 3) customization['Level2_names'] = this.delivery?.place_name?.name; customization['Level2_id'] = this.delivery?.place_name?.id
        //   if (this.delivery.country_id === 4) customization['Level3_name'] = this.delivery?.place_name?.name; customization['Level3_id'] = this.delivery?.place_name?.id
        //   if (this.delivery.country_id === 5) customization['Level3_name'] = this.delivery?.place_name?.name; customization['Level3_id'] = this.delivery?.place_name?.id
        //   if (this.delivery.country_id === 2) {
        //     customization['Level1_name'] = this.levels[0]?.name
        //     customization['Level1_id'] = this.levels[0]?.platform_id
        //     customization['Level2_name'] = this.levels[0]?.level2[0]?.name
        //     customization['Level2_id'] = this.levels[0]?.level2[0]?.platform_id
        //     const label4 = this.selectorsLevel?.level4?.find((sl) => sl.id === this.delivery?.place_name?.id)
        //     customization['Level4_name'] = label4?.name
        //     customization['Level4_id'] = label4?.id
        //   }
        //   this.form2[`level${level}`] = {id: Number(customization[`Level${level}_id`]), text: customization[`Level${level}_name`], name: customization[`Level${level}_name`]}
        // })
        const displayLevels = this.delivery?.country?.code.toLowerCase() === 'mx' ? [4, 2, 1] : [...internalLevels[this.delivery?.country?.code.toLowerCase()].display].reverse()
        displayLevels.map((displayLevel, index) => {
          if (index === 0) {
            this.form2[`level${displayLevel}`] = this.selectorsLevel[`level${displayLevel}`].filter(level => level.platform_id === this.delivery?.place_name.id)[0]
          } else {
            this.form2[`level${displayLevel}`] = this.selectorsLevel[`level${displayLevel}`].filter(level => level.id === this.form2[`level${displayLevels[index - 1]}`][`parent_level${displayLevel}`])[0]
          }
        })
        // } else { 
        if (this.delivery?.pudo) {
          if (this.delivery.pudo?.carrier_id) {
            this.form2.carrier_agency = this.form3.carrier
            this.form2.agency = {...this.delivery?.pudo, text: `${this.delivery?.pudo?.name} (${this.delivery?.pudo?.code})` }
            this.infoAgency = this.delivery?.pudo?.full_address
          } else {
            this.form2.pudo = {...this.delivery?.pudo, text: `${this.delivery?.pudo?.name} (${this.delivery?.pudo?.code})` }
            this.infoPudo = this.delivery?.pudo?.full_address
          }
        } 
      }
    },
    setOptionsLevels() {
      this.keysSelectorLevel.forEach((el) => {
        if (this.fields2 && this.fields2.find((field) => field.name === el)) {
          this.fields2.find((field) => field.name === el).options = this.selectorsLevel[el]
        }
      })
      this.setFiltersLevels()
    },
    setOptionsFields() {
      this.fields.find(field => field.name === 'place').options = this.selectorsLevel[`level${this.country.display[this.country.display.length - 1]}`]
      this.fields.find((field) => field.name === 'warehouse').options = this.warehouses
      this.keyFormRender++
      this.keyFormRender3++
    },
    editDelivery() {
      this.isDisabledEdit = !this.isDisabledEdit
      if (!this.isDisabledEdit) {
        this.setOptionsFields()
        this.setOptionsLevels()
        this.setOptionsCarrieLevel(true)
        if (this.country.code === 'AR') this.setSelectOptions('place', this.fields, this.places.map((place) => ({...place, text: place.name})) ?? [])
        this.fields = this.fields?.map((field) => ({...field, disabled: ['company', 'company_id', 'marketplace_id', 'marketplace', 'marketplace_code'].includes(field.name) }))
        this.fields2 = this.fields2?.map((field) => ({...field, disabled: this.delivery?.country_id === 2 ? ['level1', 'level2'].includes(field.name) : false}))
        this.fields3 = this.fields3?.map((field) => ({...field, disabled: ['tracking_number'].includes(field.name)}))
        if (this.fields2.findIndex((field) => field.name === 'place') !== -1) this.fields2.find((field) => field.name === 'place').options = this.places ?? []
        if (this.fields2.findIndex((field) => field.name === 'pudo') !== -1) this.fields2.find((field) => field.name === 'pudo').options = this.pudos
        if (this.showTypeAndAtrribute) {
          this.form3.type = this.types?.find((type) => type.id === this.delivery?.extra_fields['carrier_type']?.value) ?? null
          this.form3.attribute = this.attributes?.filter((attr) => this.delivery?.extra_fields['attributes']?.value?.some(item => attr.id === item)) ?? null
          this.setSelectOptions('attribute', this.fields3, this.attributes.map(attr => ({...attr, disabled: false})) ?? [])
        }
        this.keyFormRender++
        this.keyFormRender2++
        this.keyFormRender3++
      } else {
        this.setInitialData()
        this.setInitialForm()
      }
    },
    setFiltersLevels() {
      this.keysSelectorLevel.forEach((el, index) => {
        if (this.fields2 && this.fields2.find((field) => field.name === el)) {
          this.changePlaceLevel(null, this.form2[el], this.keysSelectorLevel[index + 1])
        }
      })
    },
    changeDistributionType (name, value) {
      if (name === 'distributionType') {
        this.updateField(this.fields3, 'carrier', false)
        this.form = {
          ...this.form,
          carrier: null,
          service: null,
          attribute: null,
          zipcode: null,
          pudo: null,
          agency: null,
          full_address: null
        }
        this.form2.carrier_agency = null
        this.changePudo('', null)
        this.changeCarrierAgency('', null)
        if (this.selectorsLevel2) Object.keys(this.selectorsLevel2).map(level => this.form[level] = null)
      }
      this.fields2 = this.fields2.filter(el => !['pudo', 'agency', 'carrier_agency'].includes(el.name)).filter(el => el.name.indexOf('level') === -1 && !['place', 'place_1', 'full_address', 'zip_code', 'infoPudo', 'infoAgency', 'intl_country'].includes(el.name))
      let index = this.fields2.findIndex(el => el.name === 'distributionType')
      // if (value.id === 'pudo') {
      //   this.fields2.splice(index + 1, 0, { 
      //     fieldType: 'FieldSelect', name: 'pudo', label: this.$t('Punto de retiro'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changePudo, options: this.pudos,
      //     searchOnType: { fx: this.getPudosSearch, nChars: 3, persistSearch: true, debounce: 300 }
      //   },
      //   { name: 'infoPudo', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
      //   )
      //   this.setSelectOptions('pudo', this.fields2, this.pudos)
      // } else if (value.id === 'agency') {
      //   this.fields2.splice(index + 1, 0, { 
      //     fieldType: 'FieldSelect', name: 'agency', label: this.$t('Agencía de carrier'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changeAgency, 
      //     searchOnType: { fx: this.getAgencyOnSearch, nChars: 3, debounce: 300 }
      //   },
      //   { name: 'infoAgency', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
      //   ) 
      //   this.setSelectOptions('agency', this.fields2, this.services.agencies)
      // } else {
      //   if (this.delivery?.country?.code?.toUpperCase() !== 'MX') {
      //     this.fields2.splice(index + 1, 0, 
      //       { fieldType: 'FieldInput', name: 'zip_code', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info' },
      //       { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info' }
      //     )
      //   }
      //   if (!!this.country?.code) this.addPlacesLevelFields(this.country)
      //   this.fields2 = this.fields2?.map((field) => ({...field, disabled: this.delivery?.country_id === 2 ? ['level1', 'level2'].includes(field.name) : false}))
      // }
      // this.keyFormRender2++
      if (value.id === 'intl') {
        this.fields2.splice(index + 1, 0, 
          { fieldType: 'FieldSelect', name: 'intl_country', label: this.$t('País'), containerClass: 'col-sm-12 col-md-6 container-info', options: this.countries,
            validation: 'required', change: this.changeIntlCountry },
          { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
        )
      } else {
        if (this.delivery?.country?.code?.toUpperCase() === 'MX') {
          this.fields2.splice(index + 1, 0,
            { fieldType: 'FieldSelect', name: 'zip_code', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', searchOnType: { fx: this.searchZipCode, nChars: 3, debounce: 300 }, validation: 'required', change: this.handleSelectZipcode }
          )
          index++
        }
        if (value.id === 'pudo') {
          this.fields2.splice(index + 1, 0, {
            fieldType: 'FieldSelect', name: 'pudo', label: this.$t('Punto de retiro'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changePudo,
            validation: 'required', dependency: `level${this.country.required}` // searchOnType: { fx: this.getPudosCompanyOnSearch, nChars: 3, debounce: 300 },
          },
          { name: 'infoPudo', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
          )
          //Condicionado por el momento solo a informacion de devolucion
        } else if (value.id === 'agency') {
          this.updateField(this.fields3, 'carrier', true)
          this.fields2.splice(index + 1, 0, 
            { fieldType: 'FieldSelect', name: 'carrier_agency', label: this.$t('Courier'), containerClass: 'col-sm-12 col-md-6 container-info', 
              validation: 'required', change: this.changeCarrierAgency, dependency: `level${this.country.required}` },
            { fieldType: 'FieldSelect', name: 'agency', label: this.$t('Agencia de courier'), containerClass: 'col-sm-12 col-md-6 container-info', change: this.changeAgency,
              validation: 'required', dependency: 'carrier_agency' }, // searchOnType: { fx: this.getPudosCarriersOnSearch, nChars: 3, debounce: 300 },
            { name: 'infoAgency', useSlot: true, containerClass: 'col-sm-12 col-md-6 container-info' }
          )
          this.updateField(this.fields2, 'carrier_agency', this.courierCompanies, 'options')
        } else if (value.id === 'home') {
          if (this.delivery?.country?.code?.toUpperCase() !== 'MX') {
            this.fields2.splice(index + 1, 0,
              { fieldType: 'FieldInput', name: 'zip_code', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info' },
              { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
            )
          }
          else {
            this.fields2.splice(index + 1, 0,
              { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'),  containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info', validation: 'required' }
            )
          }
          this.form.zipcode = null
        }
      }
      if (value.id !== 'intl') this.addPlacesLevelFields(this.country, this.levels)
      this.keyFormRender2++
    },
    addPlacesLevelFields (country, levels, fromIntl = false) {
      this.selectorsLevel2 = this.$generatePlacesLevels(this.$generateArrayPlacesFromTree(levels), Math.max(country.required), country.display)
      const texts = this.$i18nImport(`${country?.code?.toLowerCase()}.es`)[`${country?.code?.toLowerCase()}.es`]
      let indexSplice = fromIntl ? this.fields2.findIndex(el => el.name === 'intl_country') + 1 : this.fields2.findIndex(el => el.name === 'distributionType') + 1
      if (country?.code?.toUpperCase() === 'MX') {
        indexSplice = this.fields2.findIndex(el => el.name === 'zip_code') + 1
        this.selectorsLevel2 = { level4: this.selectorsLevel2.level4, level1: this.selectorsLevel2.level1, level2: this.selectorsLevel2.level2}
      }
      Object.keys(this.selectorsLevel2).map((key, index) => {
        if (key.endsWith('NaN')) return  //--------->    Validar que sucede porque pasa esto OJO    <---------
        if (country?.code?.toUpperCase() === 'MX') {
          const myField = { name: key, fieldType: 'FieldSelect', label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6' }
          if (key === 'level4') {
            myField.dependency = 'zip_code'
            myField.useLabel = true
            myField.change = this.changeOnLevel4
          } else {
            myField.dependency = true
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) this.fields2.splice(indexSplice, 0, myField)
          // if (this.form[`${key}Text`]) this.form[key] = { text: this.form[`${key}Text`], id: this.form[`${key}Text`] }
        } else {
          const myField = { fieldType: 'FieldSelect', name: key, label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6', options: this.selectorsLevel2[key].sort((a, b) => a.name > b.name ? 1 : -1) }
          if (index !== 0) {
            myField.dependency = Object.keys(this.selectorsLevel2)[index - 1]
          }
          if (index !== Object.keys(this.selectorsLevel2).length - 1) {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel2)[index + 1])
          } else {
            myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel2)[index + 1])
          }
          myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
          const indexFields = this.fields2.findIndex(el => el.name === myField.name)
          if (indexFields === -1) {
            this.fields2.splice(indexSplice, 0, myField)
          }
          // this.form[key] = this.selectorsLevel2[key].filter(e => e.text === this.form[`${key}Text`])[0]
        }
        indexSplice++
      })
    },
    addPlacesLevelFields2 (country) {
      let indexSplice = this.fields2.findIndex(el => el.name === 'distributionType') + 1
      const keysSelectorLevel = country.code === 'MX' ? Object.keys(Object.fromEntries(country.display.map((sl) => ([[`level${sl}`], this.selectorsLevel[`level${sl}`]])))) : Object.keys(this.selectorsLevel)
      keysSelectorLevel.map((key, index) => {
        const myField = { fieldType: 'FieldSelect', name: key, label: this.texts[`Places_${key}`], containerClass: 'col-sm-12 container-info col-md-6', options: this.selectorsLevel[key].sort((a, b) => a.name > b.name ? 1 : -1) }
        if (index !== 0 && this.country.code !== 'AR') {
          myField.dependency = Object.keys(this.selectorsLevel)[index - 1]
        }
        if (index !== Object.keys(this.selectorsLevel).length - 1) {
          myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
        } else {
          myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
        }
        myField.validation = (country.required || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
        const indexFields = this.fields2.findIndex(el => el.name === myField.name)
        if (indexFields === -1) {
          this.fields2.splice(indexSplice, 0, myField)
        }
        if (this.country.code === 'AR') {
          if (key === 'level1') {
            myField.searchOnType = { fx: this.getLevel1Ar, nChars: 3, debounce: 300 }
          }
          if (key === 'level3') {
            myField.searchOnType = { fx: this.getLevel3Ar, nChars: 3, debounce: 300 }
          }
          myField.change = this.changeLevelAr
        }        
        this.form2[key] = this.selectorsLevel[key].filter(e => e.text === this.form2[`${key}`])[0]
        indexSplice++
      })
      if (country.code === 'MX') {
        const indexZipCode = this.fields2.findIndex(el => el.name === 'distributionType') + 1
        const fieldZipCode = { fieldType: 'FieldSelect', name: 'zip_code', label: this.$t('Código postal'), containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', change: this.changeZipCodeMx, searchOnType: { fx: this.getZipCodeLevels, nChars: 3, persistSearch: true, debounce: 600 } }
        this.fields2.splice(indexZipCode, 0, fieldZipCode)
        const indexFullAdrress = this.fields2.findIndex(el => el.name === keysSelectorLevel[keysSelectorLevel.length - 1]) + 1
        const fieldFullAdrress = { fieldType: 'FieldInput', name: 'full_address', label: this.$t('Dirección'), containerClass: 'col-sm-12 col-md-12 col-lg-12 container-info' }
        this.fields2.splice(indexFullAdrress, 0, fieldFullAdrress)
      }
      // this.changeAllowEditForm()
    },
    changePlaceLevel (name, value, next) {
      if (this.form2.distributionType.id === 'agency') {
        this.form2.carrier_agency = null
        this.form2.agency = null
        this.changeCarrierAgency('', null)
      } 
      if (!value) {
        this.form2[next] = null
        return
      }
      const index = this.fields2.findIndex(el => el.name === next)
      if (next) this.fields2[index].options = value[next].sort((a, b) => a.name > b.name ? 1 : -1)
      if (this.form2.distributionType.id === 'pudo') {
        this.getPudosCompanyOnSearch(null) 
      } 
    },
    
    searchZipCode(val) {
      const params = {
        country_id: 2
      }
      const queryParams = {
        str: val
      }
      return this.baseService.callService('getZipcodeCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data.zip_codes.map(val => ({'name': val, 'id': val}))
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleSelectZipcode(name, value) {
      this.form2 = {
        ...this.form2,
        level4: null
      }
      this.changeOnLevel4('', this.form2.level4)
      if (!value) {
        this.keyFormRender2++
        return
      }
      const params = {
        country_id: this.country.id
      }
      const queryParams = {
        'str': '',
        'zip-code': value.name
      }
      this.baseService.callService('getPlacesCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data.map(place => ({...place, text: place.name}))
          this.setSelectOptions('level4', this.fields2, response)
        })
        .catch(err => {
          console.error(err)
        })
    },
    handleChangeColony(_, val) {
      this.form2.level1 = val?.level2?.name || null
      this.form2.level2 = val?.level3?.name || null
      this.formRenderLogistic2++
    },
    changeOnLevel4(name, value) {
      this.form2 = {
        ...this.form2,
        level1: value?.level1 ? { ...value?.level1, text: value.name } : null,
        level2: value?.level2 ? { ...value?.level2, text: value.name } : null
      }
      if (this.form2.distributionType.id === 'agency') { 
        this.form2 = {
          ...this.form2,
          carrier_agency: null,
          agency: null
        }
        this.changeCarrierAgency('', null)
      }
      if (!!value) Object.keys(this.selectorsLevel).filter(level => level !== name).map(level => this.form[level] = { ...value[level], text: value[level].name })
      this.keyFormRender2++
    },
    changeCarrierAgency (name, value) {
      this.form2.agency = null
      if (value !== null) {
        this.form3.carrier = value
        this.infoAgency = null
        this.changeCarrier(null, value)
        this.getPudosCarriersOnSearch(null)
      } else {
        this.form3.carrier = null
      }
    },
    getPudosCarriersOnSearch (search = null) {
      const queryParams = {
        'country.id': this.country.id,
        'address.place.level1.id': this.form2[`level${internalLevels[this.country.code.toLowerCase()].required}`].id,
        'owner.type': 'Courier',
        'carrier.code': this.form3.carrier.code
      }
      if (search !== null) queryParams['name__like__or__code__like'] = search
      const service = 'getPudosForType'
      return this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCarriers = resp.data.map(el => {
            el.text = `${el.name} (${el.code})`
            return el
          })
          this.setSelectOptions('agency', this.fields2, pudosCarriers)
        })
    },
    getPudosCompanyOnSearch (search = null) {
      const queryParams = {
        'country.id': this.country.id,
        'address.place.level1.id': this.form2[`level${internalLevels[this.country.code.toLowerCase()].required}`].id,
        'owner.id': this.form.company_id,
        // 'owner.type': 'Seller,Organization',
        'paginate_by': 100,
        'page': 1
      }
      if (search !== null) queryParams['name__like__or__code__like'] = search
      const service = 'getPudosForType'
      this.baseService.callService(service, queryParams)
        .then(resp => {
          const pudosCompany = resp.data.map(pudo => {
            pudo.text = `${pudo.name} (${pudo.code})`
            return pudo
          })
          this.setSelectOptions('pudo', this.fields2, pudosCompany)
        })
    },
    getZipCodeLevels(value) {
      const service = { name: 'getZipcode', params: { country_id: this.delivery?.country_id}, queryParams: {str: `${value}`} }
      this.$store.dispatch('fetchService', service)
    },
    changeZipCodeMx(name, value) {
      const service = { name: 'getLevels', params: { country: this.country?.code?.toLowerCase()}, queryParams: {zip_code: value?.id} }
      if (value) this.$store.dispatch('fetchService', service)
    },
    
    setSelectOptions (name, fields, options) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
      }
      this.keyFormRender1++
      this.keyFormRender2++
      this.keyFormRender3++
    },
    changePudo2 (name, value) {
      value === null ?  this.infoPudo = null : this.infoPudo = `${value.full_address}`
      this.keyFormRender2++
    },
    changePudo (name, value) {
      this.infoPudo = null
      if (!!value) {
        this.infoPudo = `${value.address.full_address}`
      }
      this.keyFormRender2++
    },
    getPudosSearch(value) {
      const service = { name: 'getPudosPlat', queryParams: { search_by: `${value}` }, hideAlert: true }
      if (value) this.$store.dispatch('fetchService', service)
    },
    generateTimeline (data) {
      // Segun los estados que llegan, los homologo a la estructura base "Groups" que definí
      const keys_status = data.collectionStatusHistory.map((status, index) => { 
        return Object.keys(this.groups).filter(group => (this.groups[group].scope.includes(status.id))).map(group => this.groups[group].status.code)[0]
      })
      // En este ultimo paso, elimino los keys de estados repetidos si existiecen 
      // y termino por inyectar los estados (según los keys) con los formatos definidos en this.groups
      keys_status.filter((key, index) => { return keys_status.indexOf(key) === index }).map((status) => { this.timeline.push({ ...this.groups[status].status, active: true }) })
      // Ya acá tengo el timeline con los estados que llegaron, 
      // ahora evaluo las condiciones para saber si tengo que agregar mas estados "esperados" que no estarán activos aún en el timeline
      let statusTimelineBase = []
      if (this.timeline[this.timeline.length - 1].code !== 'NOT_COLLECT_APPLY') {
        statusTimelineBase = ['TO_COLLECT', 'COLLECT_TO_CONFIRM', 'COLLECTED_BY_ENVIAME', 'COLLECTED_PAYED']
      } else { 
        statusTimelineBase = ['TO_COLLECT', 'COLLECT_TO_CONFIRM', 'NOT_COLLECT_APPLY']
      }
      statusTimelineBase.map((status, index) => { 
        if (!keys_status.includes(status)) this.timeline.splice(index, 0, this.groups[status].status)
        if ((this.timeline.length - 1 > index) && this.timeline[index + 1]?.active) this.timeline[index].active = true
      })
      if (this.timeline[this.timeline.length - 1].code === 'NOT_COLLECT_APPLY' && this.timeline[this.timeline.length - 1].active) {
        this.timelineCODstyleColor = '#ea5455' // danger color
      } else if (this.timeline[this.timeline.length - 1].code === 'COLLECTED_PAYED' && this.timeline[this.timeline.length - 1].active) {
        this.timelineCODstyleColor = '#1f9d57' // success color
      } else this.timelineCODstyleColor = null
      this.schema = [
        {label: 'Estado', sortable: true, key: 'name'},
        {label: 'Fecha', sortable: true, key: 'date'}
      ]
      this.rows = data.collectionStatusHistory
    },
    changeLevelAr(name, value) {
      this.form2[name] = value
    },
    getLevel1Ar(value) {
      const onSuccess = (res) => {
        this.setSelectOptions('level1', this.fields2, res.data.map((place) => ({...place, text: place.name})))
      }
      const service = { name: 'getPlacesPlat', params: { country_id: this.delivery?.country_id }, queryParams: { str: `${value}`, level: 20 }, hideAlert: true, onSuccess }
      this.$store.dispatch('fetchService', service)
    },
    getLevel3Ar(value) {
      const onSuccess = (res) => {
        this.setSelectOptions('level3', this.fields2, res?.data?.map((data) => ({ ...data, text: data?.name })))
      }
      const service = { name: 'getPlacesPlat', params: { country_id: this.delivery?.country_id }, queryParams: { str: `${value}`, parent: this.form2.level1.id }, hideAlert: true, onSuccess }
      this.$store.dispatch('fetchService', service)
    },
    // changeCarrier(name, value) {
    //   this.form3[name] = value
    // },
    changeCarrier (name, value) {
      if (value === null) {
        this.form3.service = null
        this.setSelectOptions('service', this.fields3, [])
        // this.$emit('watchers', { changeMultipackage: false, cancelDelivery: false })
      } else {
        let services = value.services.filter(serv => serv.is_delivery)
          .filter(
            service => (service.contract_type !== null && service.contract_type === value.accounts[0].contract_type) || service.contract_type === null
          ).filter(
            service => !value.accounts[0].blocked_service_ids.includes(service.id)
          )
        if (!(this.delivery?.marketplace?.code === 'WalmartChile' && value.code.toLowerCase() === 'chx')) {
          services = services.filter(
            service => !['ampliadoPlus', 'encomiendaGrandeExtendido', 'externo', 'extendido', 'ampliado'].includes(service.code)
          )
        }
        if (name === 'carrier') this.form3.service = services[0]
        this.setSelectOptions('service', this.fields3, services)
        if (value.types) this.setSelectOptions('type', this.fields3, value.types)
        // this.$emit('watchers', { changeMultipackage: !!value.multipackage, cancelDelivery: !!value.cancelable })
      }
    },
    setOptionsCarrieLevel(setServices = false) {
      this.setSelectOptions('carrier', this.fields3, this.carriers?.map((carrier) => ({...carrier, text: carrier.text, id: carrier.code})) ?? [])
      if (!!setServices) this.setSelectOptions('service', this.fields3, this.services)
      if (this.showTypeAndAtrribute) {
        this.form3.type = this.types?.find((type) => type.id === this.delivery?.extra_fields['carrier_type']?.value) ?? null
        this.form3.attribute = this.attributes?.filter((attr) => this.delivery?.extra_fields['attributes']?.value?.every(item => attr.id === item)) ?? null
        this.setSelectOptions('type', this.fields3, this.types ?? [])
        this.setSelectOptions('attribute', this.fields3, this.attributes ?? [])
        if (this.delivery?.extra_fields['attributes']?.value) this.form3.attribute = this.attributes?.filter((attr) => this.delivery?.extra_fields['attributes']?.value?.some(item => attr.id === item)) ?? null
      }
    },
    goCompany() {
      window.open(`${this.urlPlatform}/companies/${this.companyId}`, '_blank')
    },
    updateField (fields, name, value = true, prop = 'disabled') {
      const index = fields.findIndex((el) => el.name === name)
      fields[index][prop] = value
    },
    confirmAddressFromMap(payloadD) {
      this.dataDelivery = {
        payload: payloadD,
        country_id: this.delivery?.country_id
      }
      // Abrimos el modal
      this.$bvModal.show('ModalMaps')
    }
  }
}
</script>
<style>
#undefined_attribute > div > div > span {
  background-color: #044389;
}
</style>